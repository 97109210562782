import {ActivityDisplayState} from './activity-display.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {AppState} from '../../../store/states/app.state';


export class ActivityDisplaySelectors {

  private static select = {
    isVisible: (state: AppState) => state.activityDisplay.isVisible,
    isOpen: (state: AppState) => state.activityDisplay.isOpen,
  };

  public static isVisible = createSelector(
    this.select.isVisible,
    identity,
  );

  public static isOpen = createSelector(
    this.select.isOpen,
    identity,
  );

}
