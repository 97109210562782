import {createReducer, on} from '@ngrx/store';
import {activityDisplayInitialState} from './activity-display.initial.state';
import {ActivityDisplayActions} from './activity-display.actions';


export const activityDisplayReducer = createReducer(
  activityDisplayInitialState,

  on(
    ActivityDisplayActions.show,
    (state) => ({
      ...state,
      isVisible: true,
    }),
  ),

  on(
    ActivityDisplayActions.hide,
    (state) => ({
      ...state,
      isVisible: false,
    }),
  ),

  on(
    ActivityDisplayActions.open,
    (state) => ({
      ...state,
      isOpen: true,
    }),
  ),

  on(
    ActivityDisplayActions.close,
    (state) => ({
      ...state,
      isOpen: false,
    }),
  ),
);
