import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Activity} from '../../interfaces/activity.interface';
import {ActivityDisplayService} from './activity-display.service';
import {Subject, take} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NavigationService} from '@adnova/jf-ng-components';
import {Store} from '@ngrx/store';
import {ActivityDisplayActions} from './+store/activity-display.actions';
import {ActivityDisplaySelectors} from './+store/activity-display.selectors';
import {MatAccordion, MatExpansionPanel} from '@angular/material/expansion';
import {activityDisplayInitialState} from './+store/activity-display.initial.state';
import {AppState} from '../../store/states/app.state';


@Component({
  selector: 'bo-activity-display',
  templateUrl: './activity-display.component.html',
  styleUrls: ['./activity-display.component.scss']
})
export class ActivityDisplayComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild(MatAccordion)
  accordion?: MatAccordion;

  @ViewChild(MatExpansionPanel)
  expansionPanel?: MatExpansionPanel;

  // INFO: Die Id des ausgewählten Inhabers.
  private currentInhaberId = '';

  // INFO: Die Liste der Aktivitäten
  public activities: Activity[] = [];

  // INFO: Das Mat-expansion-panel soll initial immer geschlossen sein.
  public isExpansionpanelOpen = activityDisplayInitialState.isOpen;

  // INFO: Anzahl der laufenden Aktivitäten
  public activitiesOngoingCount = 0;

  // INFO: Das Mat-expansion-panel soll initial immer ausgeblendet sein.
  public showActivityDisplay = false;

  // INFO: Die Anzeige des Mat-expansion-panel soll initial immer erlaubt sein.
  public neverShowActivityDisplay = false;

  constructor(
    private navigationService: NavigationService,
    private activityDisplayService: ActivityDisplayService,
    private _store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this._store.select(ActivityDisplaySelectors.isOpen).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isOpen => {
      this.isExpansionpanelOpen = isOpen;
      this.openOrCloseExpansionPanel();
    });

    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if (!inhaber) return;
      const inhaberId = inhaber.id;

      this.currentInhaberId = inhaberId;

      this.activityDisplayService.getActivities(inhaberId).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(activities => {

        if (this.currentInhaberId === inhaberId) {
          // INFO: Zählen der laufenden Aktivitäten
          let activitiesOngoingCount = 0;
          for (const activity of activities) {
            if (!activity.done) {
              activitiesOngoingCount += 1;
            }
          }

          // INFO: Wenn die Anzahl der Aktivitäten sich geändert hat, soll das Mat-expansion-panel geöffnet werden ...
          const differentActivities = this.activities.length !== activities.length;

          // INFO: ... außer wenn die letzte Aktivität eine Lösch-Aktivität ist.
          const lastIsNotDeleting = activities.length > 0 && !activities[0].title.toLowerCase().includes('löschen');

          if (differentActivities && lastIsNotDeleting) {
            this._store.dispatch(ActivityDisplayActions.open());
            this.openOrCloseExpansionPanel();
          }

          this.activitiesOngoingCount = activitiesOngoingCount;
          // INFO: Aktualisieren der Aktivitäten.
          this.activities = activities;
        }
      });

      // INFO: Ermitteln, ob die Aktivitäten-Anzeige bei dem Inhaber dargestellt werden soll.
      this.activityDisplayService.showActivityDisplayByInhaber(this.currentInhaberId).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(show => {
        this.showActivityDisplay = show;
      });
    });

    // INFO: Ermitteln, ob die Aktivitäten-Anzeige grundsätzlich angezeigt werden darf.
    this.activityDisplayService.neverShowActivityDisplay.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(neverShow => {
      this.neverShowActivityDisplay = neverShow;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Setzen des State des Mat-expansion-panel.
   * @param isOpen Offen oder nicht.
   */
  changeExpansionpanelState(isOpen: boolean): void {
    if (isOpen) {
      this._store.dispatch(ActivityDisplayActions.open());
    } else {
      this._store.dispatch(ActivityDisplayActions.close());
    }
  }

  /**
   * Triggert das Ausblenden der Aktivitäten-Anzeige.
   * @param event Das Event, welches das Mat-expansion-panel wirft.
   */
  hideActivityDisplay(event: MouseEvent): void {
    // INFO: Die Aktivitäten-Anzeige soll nicht ein- oder ausklappen, wenn sie geschlossen wird.
    event.stopPropagation();

    this.activityDisplayService.activityDisplayShouldHide(this.currentInhaberId);
  }

  private openOrCloseExpansionPanel() {
    this._store.select(ActivityDisplaySelectors.isOpen).pipe(
      take(1),
    ).subscribe(isOpen => {
      if (this.expansionPanel && isOpen) {
        this.expansionPanel.open();
      } else if (this.expansionPanel) {
        this.expansionPanel.close();
      }
    });
  }
}
