import {createAction} from '@ngrx/store';


export class ActivityDisplayActions {
  static readonly prefix = '[ActivityDisplay]';

  static readonly show = createAction(
    `${ActivityDisplayActions.prefix} Show`,
  );

  static readonly hide = createAction(
    `${ActivityDisplayActions.prefix} Hide`,
  );

  static readonly open = createAction(
    `${ActivityDisplayActions.prefix} Open`,
  );

  static readonly close = createAction(
    `${ActivityDisplayActions.prefix} Close`,
  );
}
