import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {AppState} from '../states/app.state';
import {belegEntitiesReducer, initialBelegEntitiesState} from './beleg-entities.reducer';
import {initialOverviewTableState, overviewTableReducer} from './overview-table.reducer';
import {initialPreviewDialogState, previewDialogReducer} from './preview-dialog.reducer';
import {initialMoveBelegeDialogState, moveBelegDialogReducer} from './move-beleg-dialog.reducer';
import {initialSpliceState, spliceReducer} from './splice.reducer';
import {deleteDialogReducer, initialDeleteDialogState} from './delete-dialog.reducer';
import {dubletteReducer, initialDubletteState} from './dublette.reducer';
import {belegStipulatedDialogReducer, initialBelegStipulatedDialogState} from './beleg-stipulated-dialog.reducers';
import {initialReuseBelegDialogState, reuseBelegDialogReducer} from './reuse-beleg-dialog.reducers';
import {initialStipulateDialogState, stipulateDialogReducer} from './stipulate-dialog.reducer';
import {editReducer, initialEditState} from './edit.reducer';
import {initialZahlungEntitiesState, zahlungEntitiesReducer} from './zahlung-entities.reducer';
import {initialJfLibState, jfLibReducers} from '@adnova/jf-ng-components';
import {belegAktionenEntitiesReducer, initialBelegAktionenEntitiesState} from './beleg-aktionen-entities.reducer';
import {activityDisplayInitialState} from '../../modules/activity-display/+store/activity-display.initial.state';
import {activityDisplayReducer} from '../../modules/activity-display/+store/activity-display.reducer';


export const initialAppState: AppState = {
  ...initialJfLibState,
  belegEntities: initialBelegEntitiesState,
  overviewTable: initialOverviewTableState,
  edit: initialEditState,
  splice: initialSpliceState,
  deleteDialog: initialDeleteDialogState,
  previewDialog: initialPreviewDialogState,
  moveBelegDialog: initialMoveBelegeDialogState,
  dublette: initialDubletteState,
  zahlungEntities: initialZahlungEntitiesState,
  belegStipulatedDialog: initialBelegStipulatedDialogState,
  reuseBelegDialog: initialReuseBelegDialogState,
  stipulateDialog: initialStipulateDialogState,
  belegAktionenEntities: initialBelegAktionenEntitiesState,
  activityDisplay: activityDisplayInitialState,
};

export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,
  belegEntities: belegEntitiesReducer,
  overviewTable: overviewTableReducer,
  edit: editReducer,
  splice: spliceReducer,
  previewDialog: previewDialogReducer,
  moveBelegDialog: moveBelegDialogReducer,
  deleteDialog: deleteDialogReducer,
  dublette: dubletteReducer,
  zahlungEntities: zahlungEntitiesReducer,
  belegStipulatedDialog: belegStipulatedDialogReducer,
  reuseBelegDialog: reuseBelegDialogReducer,
  stipulateDialog: stipulateDialogReducer,
  belegAktionenEntities: belegAktionenEntitiesReducer,
  activityDisplay: activityDisplayReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
